import React, { useState, useEffect } from "react";
import Axios from "axios";
import DOMPurify from "dompurify";
import HTMLParse from "html-react-parser";
import { Row, Col, Image } from "react-bootstrap";

//STYLES
import * as PageViewerStyles from "./styles/pageViewer";
import * as EnvironmentPageViewerStyles from "../../../environment/globalStyles/pageViewerEnvStyles";

import ContactPod from "./contactPod/contactPod";
import { EnvironmentPodsMap_PageViewer } from "../../../environment/pods/environmentPodsMap";

function PageViewer(props) {
    const [layout, setLayout] = useState([]);
    const [editorials, setEditorials] = useState([]);
    const [images, setImages] = useState([]);

    useEffect(() => {
        onStart();
    }, [props.pageID]);

    function onStart() {
        let pod = "default";
        if (props.pod != null) {
            pod = props.pod;
        }
        const data = { pod: pod, pageID: props.pageID };

        Axios.post("/adminPods/pageViewer/getPageViewerInfo", data)
            .then((res) => {
                console.log(res.data);
                setLayout(res.data.layout);
                setEditorials(res.data.editorials);
                setImages(res.data.images);
            })
            .catch((err) => console.log(err));
    }

    function FindEditorial(id) {
        const editorial = editorials.find((editorial) => editorial.uuid == id);
        if (editorial != null) {
            let editorialText = editorial.content;

            const editorialImages = images.find((images) => images.editorial == editorial.uuid);
            if (editorialImages != null) {
                for (const image of editorialImages.images) {
                    editorialText = editorialText.replace(
                        `{${image.id}}`,
                        `<img style="max-width:100%; max-height:100%; float:${image.positioning}; margin-right:10px; margin-bottom:10px;" src="/content/${image.url}" width=${image.width} height=${image.height}/>`
                    );
                }
            }

            return editorialText;
        }
    }

    function FindTitle(id) {
        const editorial = editorials.find((editorial) => editorial.uuid == id);
        if (editorial != null) {
            return editorial.title;
        }
    }

    function FindTitleShow(id) {
        const editorial = editorials.find((editorial) => editorial.uuid == id);
        if (editorial != null) {
            return editorial.titleShow;
        }
    }

    function FindTitlePosition(id) {
        const editorial = editorials.find((editorial) => editorial.uuid == id);
        if (editorial != null) {
            return editorial.titlePosition;
        }
    }

    function getVideoId(url) {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
        const match = url.match(regExp);

        return match && match[2].length === 11 ? match[2] : null;
    }

    return (
        <div style={PageViewerStyles.body}>
            {layout.map((row, index) => {
                return (
                    <Row key={index}>
                        {row.columns.map((column, index) => {
                            return (
                                <Col key={index} md={column.width} style={PageViewerStyles.border}>
                                    {column.podCode != "" ? (
                                        <div style={EnvironmentPageViewerStyles.slotBackground}>
                                            {column.system == "true" ? (
                                                <div>
                                                    {column.podCode == "ADMIN_EDITORIALS" && (
                                                        <div>
                                                            {FindTitleShow(column.addInfo) == "true" ? (
                                                                <h1
                                                                    style={{
                                                                        textAlign: FindTitlePosition(column.addInfo)
                                                                    }}
                                                                >
                                                                    {FindTitle(column.addInfo)}
                                                                </h1>
                                                            ) : null}
                                                            <br />
                                                            {HTMLParse(DOMPurify.sanitize(FindEditorial(column.addInfo)))}
                                                        </div>
                                                    )}
                                                    {column.podCode == "ADMIN_CONTACTS" && (
                                                        <div>{/* <ContactPod width={column.width} id={column.addInfo} /> */}</div>
                                                    )}
                                                    {column.podCode == "ADMIN_IMAGE" && (
                                                        <div>
                                                            <Image src={`/content/${column.addInfo}`} style={PageViewerStyles.image} />
                                                        </div>
                                                    )}
                                                    {column.podCode == "ADMIN_VIDEOS" && (
                                                        <div style={PageViewerStyles.videoBody}>
                                                            <iframe
                                                                width="560"
                                                                height="315"
                                                                src={`//www.youtube.com/embed/${getVideoId(column.url)}`}
                                                                title="YouTube video player"
                                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                                allowFullScreen
                                                            ></iframe>
                                                        </div>
                                                    )}
                                                </div>
                                            ) : (
                                                <div>
                                                    {column.segmented == "true" ? (
                                                        <div>
                                                            <EnvironmentPodsMap_PageViewer
                                                                podCode={column.podCode}
                                                                podCodeExtra={column.podCodeExtra}
                                                                addInfo={column.addInfo}
                                                            />
                                                        </div>
                                                    ) : (
                                                        <div>
                                                            <EnvironmentPodsMap_PageViewer podCode={column.podCode} addInfo="" />
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    ) : null}
                                </Col>
                            );
                        })}
                    </Row>
                );
            })}
        </div>
    );
}

export default PageViewer;
