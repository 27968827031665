import React, { useState, useEffect } from "react";
import { Modal, Button, Card, Form } from "react-bootstrap";
import Axios from "axios";
import { useDispatch, useSelector } from "react-redux";

//STYLES
import * as PageSettingsStyles from "../styles/pageSettings";

//ACTIONS
import * as GS_pageSettingsActions from "../../../../store/actions/globalSettings/GS_pageSettings";
import * as MediaActions from "../../../../store/actions/media";

//COMPONENTS
import EnvironmentPodsMap, { SegmentedMenus } from "../../../../environment/pods/environmentPodsMap";

function ItemsViewer() {
    const dispatch = useDispatch();

    const GS_pageSettings = useSelector((state) => state.GS_pageSettings);

    const [noItems, setNoItems] = useState(false);

    const [SegmentedMenuTemplate, setSegmentedMenuTemplate] = useState(null);

    const addVideoModalDefaults = {
        open: false,
        fetched: false,
        values: {
            url: "",
            name: ""
        },
        valids: {
            url: true,
            name: true
        },
        urlError: "",
        nameError: "Please enter a name"
    };

    const [addVideoModal, setAddVideoModal] = useState(addVideoModalDefaults);

    function handleAddVideoModalClose() {
        setAddVideoModal(addVideoModalDefaults);
    }

    useEffect(() => {
        if (GS_pageSettings.itemsViewer_Open) {
            onStart();
        }
    }, [GS_pageSettings.itemsViewer_Open]);

    function onStart() {
        Axios.post("/adminPods/pages/itemsViewer/onLoad")
            .then((res) => {
                const data = res.data;
                console.log(data);
                if (!data.editorialsMenu && !data.podsMenu) {
                    dispatch(GS_pageSettingsActions.ItemsViewer_SetMenu({ title: "No Items", items: [] }));
                    setNoItems(true);
                } else {
                    const items = [];
                    if (data.editorialsMenu) {
                        items.push({
                            title: "Insert an Editorial",
                            method: openEditorials
                        });
                    }
                    items.push({
                        title: "Insert a Picture",
                        method: handleOpenGallerySideBar
                    });

                    items.push({
                        title: "Insert a Video",
                        method: openVideos
                    });
                    if (data.podsMenu) {
                        items.push({
                            title: "Insert a Pod",
                            method: openPodMenu
                        });
                    }
                    const menu = {
                        title: "Add an Item",
                        backBtn: false,
                        items: items
                    };
                    dispatch(GS_pageSettingsActions.ItemsViewer_SetMenu(menu));
                }
            })
            .catch((err) => console.log(err));
    }

    function handleGoBackMenu() {
        dispatch(GS_pageSettingsActions.ItemsViewer_MenuBack());
    }

    function openEditorials() {
        Axios.post("/adminPods/pages/itemsViewer/getEditorials")
            .then((res) => {
                const data = res.data;
                const items = [];

                data.editorials.map((item) => {
                    item.method = handleSetEditorialInSlot.bind(this, item.uuid, item.title);
                    items.push(item);
                });
                const menu = {
                    title: "Add an Editorial",
                    backBtn: true,
                    items: items
                };
                dispatch(GS_pageSettingsActions.ItemsViewer_SetMenu(menu));
            })
            .catch((err) => console.log(err));
    }

    function openVideos() {
        Axios.post("/adminPods/pages/itemsViewer/getVideos")
            .then((res) => {
                const data = res.data;
                console.log(data);
                const items = [];

                items.push({ title: "Add New Video", method: handleOpenAddVideoModal });

                data.videos.map((video) => {
                    items.push({ title: video.name, method: handleSetVideoInSlot.bind(this, video.uuid, video.name) });
                });

                const menu = {
                    title: "Add a Video",
                    backBtn: true,
                    items: items
                };
                dispatch(GS_pageSettingsActions.ItemsViewer_SetMenu(menu));
            })
            .catch((err) => console.log(err));
    }

    function handleOpenAddVideoModal() {
        setAddVideoModal((prevState) => {
            return { ...prevState, open: true };
        });
    }

    function handleAddVideoTextChange(event) {
        const { value, name } = event.target;

        const values = { ...addVideoModal.values };

        values[name] = value;

        setAddVideoModal((prevState) => {
            return { ...prevState, values: values };
        });
    }

    function openPodMenu() {
        Axios.post("/adminPods/pages/itemsViewer/getPods")
            .then((res) => {
                const data = res.data;
                const items = [];
                console.log(data);

                if (data.contactsPod) {
                    items.push({
                        title: "Contacts",
                        method: openContactsMenu,
                        podWidth: 3
                    });
                }

                data.features.map((item) => {
                    let title = "";
                    if (item.subTitle != "") {
                        title = `${item.title} - ${item.subTitle}`;
                    } else {
                        title = item.title;
                    }
                    item.title = title;
                    item.method = handleSetPodInSlot.bind(this, item);
                    items.push(item);
                });
                const menu = {
                    title: "Insert a Pod",
                    backBtn: true,
                    items: items
                };
                dispatch(GS_pageSettingsActions.ItemsViewer_SetMenu(menu));
            })
            .catch((err) => console.log(err));
    }

    function openContactsMenu(editorials, pods) {
        Axios.post("/adminPods/pages/itemsViewer/getContacts")
            .then((res) => {
                const data = res.data;
                console.log(data);
                if (data.error == "null") {
                    const items = [];

                    data.contacts.map((contact) => {
                        items.push({
                            title: contact.name,
                            method: handleSetContactInSlot.bind(this, contact.userID, contact.name)
                        });
                    });
                    const menu = {
                        title: "Insert a Contact",
                        backBtn: true,
                        items: items
                    };
                    dispatch(GS_pageSettingsActions.ItemsViewer_SetMenu(menu));
                }
            })
            .catch((err) => console.log(err));
    }

    function handleOpenGallerySideBar() {
        dispatch(MediaActions.UpdateCurrentUri("media/home"));
        dispatch(MediaActions.UpdateGallerySideBarOpen("adminPods/ADMIN_MEDIA", "ADMIN_PAGESETTINGS_INSERTIMAGE", true));
        dispatch(GS_pageSettingsActions.ItemsViewer_Close());
    }

    function handleSetEditorialInSlot(id, title) {
        dispatch(GS_pageSettingsActions.SetPodToLayout(`Editorials - ${title}`, "true", "ADMIN_EDITORIALS", "", id));

        handleCloseItemViewer();
    }

    function handleSetContactInSlot(id, fullName) {
        dispatch(GS_pageSettingsActions.SetPodToLayout(`Contact - ${fullName}`, "true", "ADMIN_CONTACTS", "", id));
        handleCloseItemViewer();
    }

    function handleSetVideoInSlot(id, name) {
        console.log(id);
        dispatch(GS_pageSettingsActions.SetPodToLayout(`Video - ${name}`, "true", "ADMIN_VIDEOS", "", id));
        handleCloseItemViewer();
    }

    function handleSetPodInSlot(item) {
        if (item.segmented == "true") {
            setSegmentedMenuTemplate(SegmentedMenus(`${item.frontEndCode}_SEGMENTED`));
        } else {
            dispatch(GS_pageSettingsActions.SetPodToLayout(CapsFirstLetter(item.title), "false", item.frontEndCode, "", ""));
            handleCloseItemViewer();
        }
    }

    function handleSetSegmentItemInSlot(pod, segment) {
        dispatch(GS_pageSettingsActions.SetPodToLayout(`${pod.title} - ${segment.title}`, "false", pod.frontEndCode, "", segment.uuid));
        handleCloseItemViewer();
    }

    function CapsFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    function handleCloseItemViewer() {
        dispatch(GS_pageSettingsActions.ItemsViewer_Close());
    }

    function handleFindVideo() {
        if (addVideoModal.values.url == "") {
            const valids = { ...addVideoModal.valids };
            valids.url = false;

            setAddVideoModal((prevState) => {
                return { ...prevState, valids: valids, urlError: "Please enter an URL" };
            });
        } else {
            const data = { url: addVideoModal.values.url };
            Axios.post("/adminPods/pages/itemsViewer/findVideo", data)
                .then((res) => {
                    const data = res.data;
                    if (data.error == "null") {
                        const values = { ...addVideoModal.values };
                        values.name = data.title;

                        const valids = { ...addVideoModal.valids };
                        valids.url = true;

                        setAddVideoModal((prevState) => {
                            return { ...prevState, values: values, valids: valids, fetched: true };
                        });
                    } else {
                        const valids = { ...addVideoModal.valids };
                        valids.url = false;

                        setAddVideoModal((prevState) => {
                            return { ...prevState, valids: valids, urlError: data.message };
                        });
                    }
                })
                .catch((err) => console.log(err));
        }
    }

    function handleAddVideo() {
        if (addVideoModal.values.name == "") {
            const valids = { ...addVideoModal.valids };
            valids.name = false;

            setAddVideoModal((prevState) => {
                return { ...prevState, valids: valids };
            });
        } else {
            const valids = { ...addVideoModal.valids };
            valids.name = true;

            setAddVideoModal((prevState) => {
                return { ...prevState, valids: valids };
            });

            const data = { name: addVideoModal.values.name, url: addVideoModal.values.url };
            Axios.post("/adminPods/pages/itemsViewer/addVideo", data)
                .then((res) => {
                    const data = res.data;
                    if (data.error == "null") {
                        const items = [];

                        items.push({ title: "Add New Video", method: handleOpenAddVideoModal });

                        data.videos.map((video) => {
                            items.push({ title: video.name, method: handleSetVideoInSlot.bind(this, video.uuid, video.name) });
                        });

                        const menu = {
                            title: "Add a Video",
                            backBtn: true,
                            items: items
                        };
                        dispatch(GS_pageSettingsActions.ItemsViewer_SetMenu_WithoutHistory(menu));
                        setAddVideoModal(addVideoModalDefaults);
                    }
                })
                .catch((err) => console.log(err));
        }
    }

    return (
        <div>
            <div>
                <Modal show={GS_pageSettings.itemsViewer_Open} onHide={handleCloseItemViewer}>
                    <Modal.Header closeButton>
                        <Modal.Title>{GS_pageSettings.itemsViewer_Menu.title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {noItems ? (
                            <div>
                                There are no items to add on your page. Please add Editorials or Pods. <br />
                                <br />
                                <strong>Please contact High-View Studios to request Pods</strong>
                            </div>
                        ) : (
                            <div>
                                {GS_pageSettings.itemsViewer_Menu.backBtn && (
                                    <div>
                                        <Card style={PageSettingsStyles.backBtnStyle} onClick={handleGoBackMenu}>
                                            <Card.Body>Back</Card.Body>
                                        </Card>
                                        <br />
                                    </div>
                                )}
                                <div>
                                    {SegmentedMenuTemplate != null && SegmentedMenuTemplate}
                                    {GS_pageSettings.itemsViewer_Menu.items.map((item, index) => {
                                        return (
                                            <div key={index}>
                                                {GS_pageSettings.itemsViewer_Menu.title == "Insert a Pod" ? (
                                                    <div>
                                                        {item.podWidth <= GS_pageSettings.insert.colWidth ? (
                                                            <div>
                                                                <Card style={PageSettingsStyles.cardStyle} onClick={item.method}>
                                                                    <Card.Body>{item.title}</Card.Body>
                                                                </Card>
                                                                <br />
                                                            </div>
                                                        ) : (
                                                            <div>
                                                                <Card style={PageSettingsStyles.cardRedStyle}>
                                                                    <Card.Body>
                                                                        {item.title} <br />
                                                                        {`This pod is for a wider column (${item.podWidth} minimum)`}
                                                                    </Card.Body>
                                                                </Card>
                                                                <br />
                                                            </div>
                                                        )}
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <Card style={PageSettingsStyles.cardStyle} onClick={item.method}>
                                                            <Card.Body>{item.title}</Card.Body>
                                                        </Card>
                                                        <br />
                                                    </div>
                                                )}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={handleCloseItemViewer}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={addVideoModal.open} onHide={handleAddVideoModalClose}>
                    <Modal.Header>
                        <Modal.Title>Add Video</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group>
                            <Form.Label>Video URL:</Form.Label>
                            <Form.Control
                                isInvalid={!addVideoModal.valids.url ? true : false}
                                type="text"
                                name="url"
                                disabled={addVideoModal.fetched ? true : false}
                                value={addVideoModal.values.url}
                                onChange={handleAddVideoTextChange}
                                maxLength={150}
                            />
                            <Form.Control.Feedback type="invalid">{addVideoModal.urlError}</Form.Control.Feedback>
                        </Form.Group>
                        {addVideoModal.fetched && (
                            <div>
                                <br />
                                <Form.Group>
                                    <Form.Label>Video Name:</Form.Label>
                                    <Form.Control
                                        isInvalid={!addVideoModal.valids.name ? true : false}
                                        type="text"
                                        name="name"
                                        value={addVideoModal.values.name}
                                        onChange={handleAddVideoTextChange}
                                        maxLength={200}
                                    />
                                    <Form.Control.Feedback type="invalid">{addVideoModal.nameError}</Form.Control.Feedback>
                                </Form.Group>
                            </div>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        {addVideoModal.fetched ? (
                            <Button onClick={handleAddVideo}>Add</Button>
                        ) : (
                            <Button onClick={handleFindVideo}>Find Video</Button>
                        )}
                        <Button onClick={handleAddVideoModalClose}>Close</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </div>
    );
}

export default ItemsViewer;
