import * as Colors from "../../../../environment/globalStyles/environmentColors";

export const body = {
    padding: "30px"
};

export const border = {
    padding: "10px"
};

export const image = {
    width: "100%"
};

export const videoBody = {
    textAlign: "center"
};
