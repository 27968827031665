import React, { useEffect, useState } from "react";
import { Row, Col, Button, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Axios from "axios";

//ACTIONS
import * as GS_navSettingsActions from "../../../../store/actions/globalSettings/GS_navSettings";

//STYLES
import * as ControllerStyles from "../styles/styleMenu";

function Controller() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(GS_navSettingsActions.UpdateTitle("Style Configuration"));
        dispatch(GS_navSettingsActions.UpdateSelected("Style Configuration"));
        onLoad();
    }, []);

    function onLoad() {
        Axios.post("/style/controller/load")
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    const temp = [...styles];
                    console.log(temp);
                    for (const section of data.index) {
                        const ind = temp.findIndex((s) => s.id == section.sectionID);
                        temp[ind].enabled = section.enabled == "true";
                    }

                    setStyles(temp);
                }
            })
            .catch((err) => console.log(err));
    }

    const [styles, setStyles] = useState([
        {
            section: false,
            title: "Navigation Bar",
            key: "navBar",
            editable: false
        },
        {
            section: false,
            title: "Sub Pages (Top Photo)",
            key: "subPagesPhotos",
            editable: false
        },
        {
            section: true,
            id: 1,
            title: "Slider",
            key: "slider",
            editable: true
        },
        {
            section: true,
            id: 2,
            title: "Introduction",
            key: "simpleSectionCentredText",
            editable: true
        },
        {
            section: true,
            id: 3,
            title: "Half Photo and Newsletter",
            key: "halfPhoto",
            editable: true
        },
        {
            section: true,
            id: 4,
            title: "Four Sections",
            key: "fourSectionsOneRow",
            editable: true
        },
        {
            section: true,
            id: 5,
            title: "Introductions row",
            key: "photoRow",
            editable: true
        }
    ]);

    function handleStyleLinkOnClick(key) {
        navigate(`./${key}`);
    }

    function handleViewDesignOnClick() {
        window.open("./styleConfiguration/design", "_blank");
    }

    function handleToggleEnable(id, toggle) {
        const data = { sectionID: id, toggle: toggle };
        Axios.post("/style/controller/updateSection", data)
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    const temp = [...styles];
                    for (const section of data.index) {
                        const ind = temp.findIndex((s) => s.id == section.sectionID);
                        temp[ind].enabled = section.enabled == "true";
                    }

                    setStyles(temp);
                }
            })
            .catch((err) => console.log(err));
    }

    return (
        <div style={ControllerStyles.body}>
            <div style={ControllerStyles.columnRight}>
                <Button onClick={handleViewDesignOnClick}>View Design</Button>
            </div>
            <Row>
                <Col style={ControllerStyles.columnRight}></Col>
            </Row>
            <br />
            <Row>
                <Col>
                    {styles.map((style, index) => {
                        return (
                            <div key={index}>
                                {style.editable ? (
                                    <Row>
                                        <Col>
                                            <Card
                                                style={style.enabled ? ControllerStyles.cardStyle : ControllerStyles.cardStyleDisabled}
                                                onClick={style.enabled ? handleStyleLinkOnClick.bind(this, style.key) : null}
                                            >
                                                <Card.Body>
                                                    <Row>
                                                        <Col style={ControllerStyles.cardTextSection}>
                                                            {!style.section ? <div>{style.title}</div> : `Section ${style.id}: ${style.title}`}
                                                        </Col>
                                                    </Row>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                        <Col sm={"auto"} style={ControllerStyles.columnRight}>
                                            {style.enabled ? (
                                                <Button variant="danger" onClick={handleToggleEnable.bind(this, style.id, false)}>
                                                    Disable
                                                </Button>
                                            ) : (
                                                <Button variant="success" onClick={handleToggleEnable.bind(this, style.id, true)}>
                                                    Enable
                                                </Button>
                                            )}
                                        </Col>
                                    </Row>
                                ) : (
                                    <Card style={{ ...ControllerStyles.cardStyle }} onClick={handleStyleLinkOnClick.bind(this, style.key)}>
                                        <Card.Body>
                                            <Row>
                                                {/* 16 */}
                                                <Col> {!style.section ? <div>{style.title}</div> : `Section ${style.id}: ${style.title}`}</Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                )}
                                <br />
                            </div>
                        );
                    })}
                </Col>
            </Row>
        </div>
    );
}

export default Controller;
