import * as Colors from "../../../../environment/globalStyles/administratorColors";

export const rowContainer = {
    border: "4px dashed green",
    height: "110px",
    marginBottom: "10px",
    marginLeft: "0px",
    marginRight: "0px"
};

export const rightButtons = {
    textAlign: "right"
};

export const centerColumn = {
    textAlign: "center",
    margin: "auto"
};

export const buttonHidden = {
    visibility: "hidden"
};

export const preLoadedLayoutSlot = {
    background: Colors.secondary,
    cursor: "pointer"
};

export const preLoadedSlot_Title = {
    textDecoration: "underline"
};

export const centeredText = {
    textAlign: "center",
    margin: "0",
    position: "absolute",
    top: "50%",
    msTransform: "translateY(-50%)",
    transform: "translateY(-50%)"
};

export const editorialTitle = {
    color: "red",
    fontWeight: "bold"
};

export const videoTitle = {
    color: "#df8f03",
    fontWeight: "bold"
};

export const contactsTitle = {
    color: "brown",
    fontWeight: "bold"
};

export const podsTitle = {
    color: "purple",
    fontWeight: "bold"
};

export const editorialTitleOverflow = {
    ontWeight: "bold",
    minWidth: "80px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis"
};
