import React, { useState, useEffect } from "react";
import { Row, Col, Image } from "react-bootstrap";
import Axios from "axios";
import { useNavigate } from "react-router-dom";

//STYLES
import * as FourSectionsOneRowStyles from "./styles";

function FourSectionsOneRow() {
    const navigate = useNavigate();
    const [data, setData] = useState([
        {
            active: true,
            image: "",
            text: "",
            backgroundColor: ""
        },
        {
            active: true,
            image: "",
            text: "",
            backgroundColor: ""
        },
        {
            active: true,
            image: "",
            text: "",
            backgroundColor: ""
        },
        {
            active: true,
            image: "",
            text: "",
            backgroundColor: ""
        }
    ]);

    useEffect(() => {
        Axios.post("/style/SPOD_FOURSECTIONSONEROW_ADMIN/load")
            .then((res) => {
                const respondData = res.data;
                if (respondData.error == "null") {
                    const newData = { ...data };
                    newData[0].active = respondData.info.section1Active == "true";
                    newData[0].image = respondData.info.section1Image;
                    newData[0].text = respondData.info.section1Text;
                    newData[1].active = respondData.info.section2Active == "true";
                    newData[1].image = respondData.info.section2Image;
                    newData[1].text = respondData.info.section2Text;
                    newData[2].active = respondData.info.section3Active == "true";
                    newData[2].image = respondData.info.section3Image;
                    newData[2].text = respondData.info.section3Text;
                    newData[3].active = respondData.info.section4Active == "true";
                    newData[3].image = respondData.info.section4Image;
                    newData[3].text = respondData.info.section4Text;
                    setData(newData);
                }
            })
            .catch((err) => console.log(err));
    }, []);

    function handleOnMouseEnter(id) {
        const section = { ...data[id] };

        section.backgroundColor = "rgba(255, 0, 0, 0.3)";

        setData((prevState) => {
            return { ...prevState, [id]: section };
        });
    }

    function handleOnMouseLeave(id) {
        const section = { ...data[id] };
        console.log("over");

        section.backgroundColor = "";

        setData((prevState) => {
            return { ...prevState, [id]: section };
        });
    }

    function handlePersonClick(name) {
        navigate(`./sections/${name}`);
    }

    return (
        <div>
            <Row>
                {data[0].active && (
                    <Col style={FourSectionsOneRowStyles.column}>
                        <div
                            style={{ ...FourSectionsOneRowStyles.section, backgroundColor: data[0].backgroundColor }}
                            onMouseEnter={handleOnMouseEnter.bind(this, 0)}
                            onMouseLeave={handleOnMouseLeave.bind(this, 0)}
                            onClick={handlePersonClick.bind(this, data[0].text.replace(" ", "_"))}
                        >
                            <div style={FourSectionsOneRowStyles.imageContainer}>
                                <Image src={`/content/mainStyle/fourSectionsOneRow/${data[0].image}`} style={FourSectionsOneRowStyles.image} />
                            </div>
                            <div style={FourSectionsOneRowStyles.textContainer}>{data[0].text}</div>
                        </div>
                    </Col>
                )}
                {data[1].active && (
                    <Col style={FourSectionsOneRowStyles.column}>
                        <div
                            style={{ ...FourSectionsOneRowStyles.section, backgroundColor: data[1].backgroundColor }}
                            onMouseEnter={handleOnMouseEnter.bind(this, 1)}
                            onMouseLeave={handleOnMouseLeave.bind(this, 1)}
                            onClick={handlePersonClick.bind(this, data[1].text.replace(" ", "_"))}
                        >
                            <div style={FourSectionsOneRowStyles.imageContainer}>
                                <Image src={`/content/mainStyle/fourSectionsOneRow/${data[1].image}`} style={FourSectionsOneRowStyles.image} />
                            </div>
                            <div style={FourSectionsOneRowStyles.textContainer}>{data[1].text}</div>
                        </div>
                    </Col>
                )}
                {data[2].active && (
                    <Col style={FourSectionsOneRowStyles.column}>
                        <div
                            style={{ ...FourSectionsOneRowStyles.section, backgroundColor: data[2].backgroundColor }}
                            onMouseEnter={handleOnMouseEnter.bind(this, 2)}
                            onMouseLeave={handleOnMouseLeave.bind(this, 2)}
                            onClick={handlePersonClick.bind(this, data[2].text.replace(" ", "_"))}
                        >
                            <div style={FourSectionsOneRowStyles.imageContainer}>
                                <Image src={`/content/mainStyle/fourSectionsOneRow/${data[2].image}`} style={FourSectionsOneRowStyles.image} />
                            </div>
                            <div style={FourSectionsOneRowStyles.textContainer}>{data[2].text}</div>
                        </div>
                    </Col>
                )}
                {data[3].active && (
                    <Col style={FourSectionsOneRowStyles.column}>
                        <div
                            style={{ ...FourSectionsOneRowStyles.section, backgroundColor: data[3].backgroundColor }}
                            onMouseEnter={handleOnMouseEnter.bind(this, 3)}
                            onMouseLeave={handleOnMouseLeave.bind(this, 3)}
                            onClick={handlePersonClick.bind(this, data[3].text.replace(" ", "_"))}
                        >
                            <div style={FourSectionsOneRowStyles.imageContainer}>
                                <Image src={`/content/mainStyle/fourSectionsOneRow/${data[3].image}`} style={FourSectionsOneRowStyles.image} />
                            </div>
                            <div style={FourSectionsOneRowStyles.textContainer}>{data[3].text}</div>
                        </div>
                    </Col>
                )}
            </Row>
        </div>
    );
}

export default FourSectionsOneRow;
