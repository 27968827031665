// import * as Colors from "../../../../../../../../globalStyles/administratorColors";
import * as Colors from "../../../globalStyles/administratorColors";

export const body = {
    paddingTop: "20px",
    paddingRight: "30px"
};

export const columnRight = {
    textAlign: "right"
};

export const cardStyle = {
    backgroundColor: Colors.primary,
    color: "white",
    cursor: "pointer"
};

export const cardStyleDisabled = {
    backgroundColor: "red",
    color: "white"
};

export const cardTextSection = {
    display: "flex",
    flex: "1",
    alignItems: "center",
    paddingLeft: "25px"
};
