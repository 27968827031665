import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Axios from "axios";

//ACTIONS
import * as NavBarActions from "../envStore/actions/navBar";

//COMPONENTS
import Slider from "../mainStyle/components/slider/frontEnd/slider";
import SimpleSectionCenteredText from "../mainStyle/components/simpleSectionCenteredText/frontEnd/simpleSectionCentredText";
import HalfPhoto from "../mainStyle/components/halfPhoto//frontEnd/halfPhoto";
import VideoSection from "../mainStyle/components/videoSection/frontEnd/video";
import PhotoRow from "../mainStyle/components/photoRow/frontEnd/photoRow";
import FourSectionsOneRow from "../mainStyle/components/fourSectionsOneRow/frontEnd/fourSectionsOneRow";
import EventsSlider from "../mainStyle/components/eventsSlider/eventsSlider";
import Footer from "../mainStyle/components/footer/footer";

function HomePage() {
    const dispatch = useDispatch();
    const [sections, setSections] = useState([]);

    useEffect(() => {
        dispatch(NavBarActions.UpdateTextColor("white"));

        onLoad();
    }, []);

    function onLoad() {
        Axios.post("/style/controller/load")
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    setSections(data.index);
                }
            })
            .catch((err) => console.log(err));
    }

    return (
        <div>
            {sections.length > 0 && (
                <div>
                    {sections[0].enabled == "true" && <Slider />}
                    {sections[1].enabled == "true" && <SimpleSectionCenteredText />}
                    {sections[2].enabled == "true" && <HalfPhoto />}
                    {sections[3].enabled == "true" && <FourSectionsOneRow />}
                    <VideoSection />
                    <EventsSlider />
                    {sections[4].enabled == "true" && <PhotoRow />}
                    <Footer />
                </div>
            )}
        </div>
    );
}

export default HomePage;
